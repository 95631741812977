import { ExportFormat } from "./export";
import { ProductSelector } from "./product";

export interface Config {
    language?: string;
    productsMarket?: string;
    tenant?: string;
    accessToken?: string;
}
export const Markets = ['esp', 'fra', 'ita','other'] as const;
export type ProductsMarket = typeof Markets[number];

export const Tenants = ['schneider', 'etap', 'standalone'] as const;
export type AllowedTenant = typeof Tenants[number];

export const Languages = ['en', 'fr', 'es', 'it'] as const;
export type Language = typeof Languages[number];

export const TenantExportFormat = new Map<AllowedTenant, ExportFormat[]>([
    ["etap", ["json", "csv", "nem"]],
    ["schneider", ["json", "csv", "nem"]],
    ["standalone", ["json", "csv", "nem"]]
]);

export const TenantExportButtonLabel = new Map<AllowedTenant, string>([
    ["etap", 'validate'],
    ["schneider", 'validate'],
    ["standalone", 'export']
]);

export const TenantProductSelector = new Map<AllowedTenant, ProductSelector | null>([
    ["etap", null],
    ["schneider", 'select-and-config'],
    ["standalone", 'select-and-config']
]);

export const TenantProductsMarket = new Map<AllowedTenant, boolean>([
    ["etap", false],
    ["schneider", true],
    ["standalone", true]
]);

export const TenantFeedback = new Map<AllowedTenant, boolean>([
    ["etap", false],
    ["schneider", false],
    ["standalone", true]
]);